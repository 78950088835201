
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    // limit: 20,
    // total: 0,
    orderBy: 'startDate',
    orderDesc: true,
    // searchBy: ['name'],
    // searchQuery: '',
    // where: {},
    // loading: false,
    include: [
      'guides',
      'members',
      'studios',
      'reference',
    ],
    // fields: [],
  };
}
