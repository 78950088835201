
const routes = [
  {
    path: '/',
    component: () => import('layouts/control-layout.vue'),
    meta: {
      requiredRoles: ['admin'],
    },
    children: [
      {
        path: '',
        name: 'control-home',
        component: () => import('pages/control/home-page.vue'),
        meta: {title: 'Панель управления'},
      },
      {
        path: 'roles',
        name: 'control-roles',
        component: () => import('pages/control/roles-page.vue'),
        meta: {title: 'Роли'},
      },
      {
        path: 'game-roles',
        name: 'control-game-roles',
        component: () => import('pages/control/game-roles-page.vue'),
        meta: {title: 'Гейм-роли'},
      },
      {
        path: 'users',
        name: 'control-users',
        component: () => import('pages/control/users-page.vue'),
        meta: {title: 'Пользователи'},
      },
      {
        path: 'user/:id?',
        name: 'control-user',
        component: () => import('pages/control/user-page.vue'),
        meta: {title: 'Пользователь'},
      },
      {
        path: 'companies',
        name: 'control-companies',
        component: () => import('pages/control/companies-page.vue'),
        meta: {title: 'Кампании'},
      },
      {
        path: 'vendors',
        name: 'control-vendors',
        component: () => import('pages/control/vendors-page.vue'),
        meta: {title: 'Вендоры'},
      },
      {
        path: 'vendor/:id?',
        name: 'control-vendor',
        component: () => import('pages/control/vendor-page.vue'),
        meta: {title: 'Вендор'},
      },
      {
        path: 'pages',
        name: 'control-pages',
        component: () => import('pages/control/pages-page.vue'),
        meta: {title: 'Страницы'},
      },
      {
        path: 'page/:id?',
        name: 'control-page',
        component: () => import('pages/control/page-page.vue'),
        meta: {title: 'Страница'},
      },
      {
        path: 'main-page',
        name: 'control-main-page',
        component: () => import('pages/control/main-page-page.vue'),
        meta: {title: 'Раздел «Главная»'},
      },
      {
        path: 'quiz-page',
        name: 'control-quiz-page',
        component: () => import('pages/control/quiz-page-page.vue'),
        meta: {title: 'Раздел «Викторина»'},
      },
      {
        path: 'news-page',
        name: 'control-news-page',
        component: () => import('pages/control/news-page-page.vue'),
        meta: {title: 'Раздел «Новости»'},
      },
      {
        path: 'events-page',
        name: 'control-events-page',
        component: () => import('pages/control/events-page-page.vue'),
        meta: {title: 'Раздел «Мероприятия»'},
      },
      {
        path: 'expo-page',
        name: 'control-expo-page',
        component: () => import('pages/control/expo-page-page.vue'),
        meta: {title: 'Раздел «Экспо»'},
      },
      {
        path: 'search-page',
        name: 'control-search-page',
        component: () => import('pages/control/search-page-page.vue'),
        meta: {title: 'Раздел «Поиск»'},
      },
      {
        path: 'faq-page',
        name: 'control-faq-page',
        component: () => import('pages/control/faq-page-page.vue'),
        meta: {title: 'Раздел «FAQ»'},
      },
      {
        path: 'academy',
        name: 'control-academy',
        component: () => import('pages/control/academy-page.vue'),
        meta: {title: 'Раздел «Академия»'},
      },
      {
        path: 'activities',
        name: 'control-activities',
        component: () => import('pages/control/activities-page.vue'),
        meta: {title: 'Активности'},
      },
      {
        path: 'navigation',
        name: 'control-navigation',
        component: () => import('pages/control/navigation-page.vue'),
        meta: {title: 'Навигация'},
      },
      {
        path: 'schedules',
        name: 'control-schedules',
        component: () => import('pages/control/schedules-page.vue'),
        meta: {title: 'Планировщик'},
      },
      {
        path: 'schedule/:externalId?',
        name: 'control-schedule',
        component: () => import('pages/control/schedule-page.vue'),
        meta: {title: 'Расписание'},
      },
      {
        path: 'feedbacks',
        name: 'control-feedbacks',
        component: () => import('pages/control/feedbacks-page.vue'),
        meta: {title: 'Отзывы'},
      },
      {
        path: 'feedback/:id?',
        name: 'control-feedback',
        component: () => import('pages/control/feedback-page.vue'),
        meta: {title: 'Отзыв'},
      },
      {
        path: 'requests',
        name: 'control-requests',
        component: () => import('pages/control/requests-page.vue'),
        meta: {title: 'Запросы'},
      },
      {
        path: 'request/:id?',
        name: 'control-request',
        component: () => import('pages/control/request-page.vue'),
        meta: {title: 'Запрос'},
      },
      {
        path: 'events',
        name: 'control-events',
        component: () => import('pages/control/events-page.vue'),
        meta: {title: 'Мероприятия'},
      },
      {
        path: 'event/:id?',
        name: 'control-event',
        component: () => import('pages/control/event-page.vue'),
        meta: {title: 'Мероприятие'},
      },
      {
        path: 'news',
        name: 'control-news',
        component: () => import('pages/control/news-page.vue'),
        meta: {title: 'Новости'},
      },
      {
        path: 'news-item/:id?',
        name: 'control-news-item',
        component: () => import('pages/control/news-item-page.vue'),
        meta: {title: 'Новость'},
      },
      {
        path: 'quiz-subjects',
        name: 'control-quiz-subjects',
        component: () => import('pages/control/quiz-subjects-page.vue'),
        meta: {title: 'Темы викторины'},
      },
      {
        path: 'quiz-questions',
        name: 'control-quiz-questions',
        component: () => import('pages/control/quiz-questions-page.vue'),
        meta: {title: 'Вопросы и ответы'},
      },
      {
        path: 'event-types',
        name: 'control-event-types',
        component: () => import('pages/control/event-types-page.vue'),
        meta: {title: 'Типы мероприятий'},
      },
      {
        path: 'event-categories',
        name: 'control-event-categories',
        component: () => import('pages/control/event-categories-page.vue'),
        meta: {title: 'Категории мероприятий'},
      },
      {
        path: 'subscriptions',
        name: 'control-subscriptions',
        component: () => import('pages/control/subscriptions-page.vue'),
        meta: {title: 'Подписки'},
      },
    ],
  },
  {
    path: '/dialog',
    component: () => import('layouts/dialog-layout.vue'),
    children: [
      {
        path: 'login',
        name: 'dialog-login',
        component: () => import('pages/dialog/login-page.vue'),
        meta: {title: 'Авторизация'},
      },
      {
        path: 'logout',
        name: 'dialog-logout',
        component: () => import('pages/dialog/logout-page.vue'),
        meta: {title: 'Деавторизация'},
      },
    ],
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('pages/error-page.vue'),
    props: true,
  },
];

// Always leave this as last one.
if (process.env.MODE !== 'ssr') {

  routes.push({
    path: '*',
    component: () => import('pages/error-page.vue'),
  });
}

export default routes;
