import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import inject from '@src/services/boot-injector';

export default inject(
  ({store}) => {

    const plugin = new VueSocketIO({
      debug: true,
      connection: process.env.SOCKET_BASE_URL,
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_',
      },
      options: {
        reconnection: false,
      },
    });

    Vue.use(plugin);

    // Reconnect.

    setInterval(
      () => !plugin.io.connected && plugin.io.connect(),
      parseInt(process.env.SOCKET_RECONNECT_SEC, 10) * 1000,
    );

    return {
      socket: plugin.io,
      vueSocketIo: plugin,
    };
  },
);
