
export default function () {

  return {
    user: null,
    login: '',
    password: '',
    loading: false,
  };
}
