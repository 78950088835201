import Vue from 'vue';
import {date} from 'quasar';

Vue.prototype.$date = date;
Vue.prototype.$date.mask = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

date.utcFormat = function (value, ...args) {

  if (!value)
    return '';

  const moment = Vue.prototype.$moment;
  return moment(value).utc().format(...args);
};

date.localFormat = function (value, ...args) {

  if (!value)
    return '';

  const moment = Vue.prototype.$moment;
  return moment(value).local().format(...args);
};

date.utcBuild = function (options = {}) {

  const value = date.buildDate(options);
  const moment = Vue.prototype.$moment;
  return moment(value).format(this.mask);
};

date.humanDate = function ({
  first,
  second = null,
  short = false,
  time = false,
  local = false,
}) {

  if (!first && !second)
    return '';

  const isSame = date.isSameDate(
    first,
    second,
  );

  let singlePattern = short
    ? 'D MMMM'
    : 'D MMMM YYYY';

  let firstPattern = 'D MMM';

  let secondPattern = short
    ? 'D MMM'
    : 'D MMM YYYY';

  if (time) {

    singlePattern += ' HH:mm';
    firstPattern += ' HH:mm';
    secondPattern += ' HH:mm';
  }

  const format = local
    ? date.localFormat
    : date.utcFormat;

  if (!second || isSame)
    return format(
      first,
      singlePattern,
    );

  const firstFormat = format(
    first,
    firstPattern,
  );

  const secondFormat = format(
    second,
    secondPattern,
  );

  return `${firstFormat} - ${secondFormat}`;
};
