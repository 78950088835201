import initialState from './state';

export function SET_SEARCH_MODULE(state, value) {

  state.searchModule = value || '';
}

export function RESET_SEARCH_MODULE(state) {

  state.searchModule = initialState().searchModule;
}
