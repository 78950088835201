import Vue from 'vue';
import Vuex from 'vuex';
import {Notify} from 'quasar';
import {createApi} from '@src/boot/api';

import createGlobalModule from './global';
import createVlNewsModule from './vlNews';
import createSessionModule from './session';
import createVlPagesModule from './vlPages';
import createVlRolesModule from './vlRoles';
import createVlUsersModule from './vlUsers';
import createVlEventsModule from './vlEvents';
import createVlVendorsModule from './vlVendors';
import createVlStudiosModule from './vlStudios';
import createProgramsModule from './vlPrograms';
import createFaqPagesModule from './vlFaqPages';
import createExpoPagesModule from './vlExpoPages';
import createVlProductsModule from './vlProducts';
import createVlChannelsModule from './vlChannels';
import createVlRequestsModule from './vlRequests';
import createVlNewsPagesModule from './vlNewsPages';
import createVlFeedbacksModule from './vlFeedbacks';
import createVlAcademiesModule from './vlAcademies';
import createVlGameRolesModule from './vlGameRoles';
import createVlCompaniesModule from './vlCompanies';
import createVlMainPagesModule from './vlMainPages';
import createVlQuizPagesModule from './vlQuizPages';
import createVlMaterialsModule from './vlMaterials';
import createVlSchedulesModule from './vlSchedules';
import createVlEventTypesModule from './vlEventTypes';
import createVlExtraUsersModule from './vlExtraUsers';
import createVlActivitiesModule from './vlActivities';
import createVlSearchPagesModule from './vlSearchPages';
import createVlEventsPagesModule from './vlEventsPages';
import createVlNavigationsModule from './vlNavigations';
import createVlQuizAnswersModule from './vlQuizAnswers';
import createVlApplicationsModule from './vlApplications';
import createVlQuizSubjectsModule from './vlQuizSubjects';
import createVlQuizQuestionsModule from './vlQuizQuestions';
import createVlSubscriptionsModule from './vlSubscriptions';
import createVlEventCategoriesModule from './vlEventCategories';
import { messageFromError } from '@src/utils';

Vue.use(Vuex);

export function onSuccess({action}) {

  if (
    action === 'FETCH_ITEM' ||
    action === 'FETCH_ITEMS'
  ) return;

  Notify.create({
    message: 'Операция выполнена.',
    color: 'positive',
  });
}

export function onError({error}) {

  return Notify.create({
    message: error.message || 'Неизвестная ошибка',
    color: 'negative',
  });
}

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function ({ssrContext}) {

  const client = createApi({ssrContext}).api;

  const options = {
    client,
    onError,
    onSuccess,
  };

  const store = new Vuex.Store({
    strict: process.env.DEV,
    modules: {
      global: createGlobalModule(options),
      vlNews: createVlNewsModule(options),
      session: createSessionModule(options),
      vlPages: createVlPagesModule(options),
      vlRoles: createVlRolesModule(options),
      vlUsers: createVlUsersModule(options),
      vlEvents: createVlEventsModule(options),
      vlVendors: createVlVendorsModule(options),
      vlStudios: createVlStudiosModule(options),
      vlPrograms: createProgramsModule(options),
      vlFaqPages: createFaqPagesModule(options),
      vlExpoPages: createExpoPagesModule(options),
      vlProducts: createVlProductsModule(options),
      vlChannels: createVlChannelsModule(options),
      vlRequests: createVlRequestsModule(options),
      vlNewsPages: createVlNewsPagesModule(options),
      vlQuizPages: createVlQuizPagesModule(options),
      vlFeedbacks: createVlFeedbacksModule(options),
      vlAcademies: createVlAcademiesModule(options),
      vlGameRoles: createVlGameRolesModule(options),
      vlCompanies: createVlCompaniesModule(options),
      vlMainPages: createVlMainPagesModule(options),
      vlMaterials: createVlMaterialsModule(options),
      vlSchedules: createVlSchedulesModule(options),
      vlEventTypes: createVlEventTypesModule(options),
      vlExtraUsers: createVlExtraUsersModule(options),
      vlActivities: createVlActivitiesModule(options),
      vlSearchPages: createVlSearchPagesModule(options),
      vlEventsPages: createVlEventsPagesModule(options),
      vlNavigations: createVlNavigationsModule(options),
      vlQuizAnswers: createVlQuizAnswersModule(options),
      vlApplications: createVlApplicationsModule(options),
      vlQuizSubjects: createVlQuizSubjectsModule(options),
      vlQuizQuestions: createVlQuizQuestionsModule(options),
      vlSubscriptions: createVlSubscriptionsModule(options),
      vlEventCategories: createVlEventCategoriesModule(options),
    },
  });

  store.subscribeAction(({type}) => {

    if (
      type.includes('FETCH_PAGE') &&
      typeof window !== 'undefined'
    ) window.scrollTo(0, 0);
  });

  return store;
}
