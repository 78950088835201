export function CHANGE_ORDER_BY({state, commit}) {
  commit('SET_ORDER_BY', state.orderBy === 'createdAt' ? 'publishedAt' : 'createdAt');
  return state.orderBy;
}

export function CHANGE_ORDER_DESC({state, commit}) {
  commit('SET_ORDER_DESC', !state.orderDesc);
  return state.orderDesc;
}

export function CHANGE_QUERY({state, commit}) {
  commit('SET_QUERY', state.searchQuery);
  return state.searchQuery;
}

export function CHANGE_WHERE({state, commit}, value) {
  commit('SET_WHERE', value);
  return state.where;
}
