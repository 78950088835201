import initialState from './state';

export function SET_WHERE(state, value) {

  state.where = value || null;
}

export function RESET_WHERE(state) {

  state.where = initialState().where;
}
