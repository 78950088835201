
export async function FETCH_ITEM_BY_EXTERNAL_ID(
  {commit, dispatch},
  {externalId},
) {

  const filter = {
    where: {externalId},
  };

  console.log(externalId);

  const items = await dispatch(
    'FETCH_ITEMS',
    {filter},
  );

  const item = items.length
    ? items[0]
    : null;

  commit('SET_ITEM', item);
  commit('SET_TEMP_ITEM', item);

  return item;
}
