
/**
 * Has role.
 *
 * @param name
 * @param user
 * @return {boolean}
 */
export function hasRole(name, user) {

  if (
    !name ||
    !user ||
    !user.role
  ) return false;

  const names = Array
    .toArray(name);

  const isAny = names
    .indexOf('*') !== -1;

  const isFound = names.indexOf(
    user.role.name,
  ) !== -1;

  return isAny || isFound;
}

/**
 * Get User Name.
 *
 * @param user
 * @param empty
 * @returns {string|string|string}
 */
export function getUserName(user, empty = '') {

  if (!user)
    return empty;

  return `${user.name} ${user.surname}`.trim() || empty;
}
