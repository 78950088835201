
export function targets() {

  return [
    {value: '', label: 'Текущее окно'},
    {value: '_blank', label: 'Новое окно'},
  ];
}

export function positions() {

  return [
    {value: '', label: 'По умолчанию'},
    {value: 'header', label: 'Меню в шапке'},
    {value: 'dropdown', label: 'Выпадающее меню'},
  ];
}

export function breakpoints() {

  return [
    {value: '', label: 'Всегда'},
    {value: 'gt-sm', label: '1024px и выше'},
    {value: 'gt-md', label: '1440px и выше'},
  ];
}
