
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    // limit: 20,
    // total: 0,
    orderBy: 'id',
    orderDesc: true,
    searchBy: [
      'id',
      'name',
      'email',
      'surname',
      'middleName',
    ],
    // searchQuery: '',
    where: {},
    // loading: false,
    include: [
      'role',
      'guide',
      'group',
      'channel',
      'company',
      'gameRole',
    ],
    // fields: [],
  };
}
