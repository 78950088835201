import {Cookies} from 'quasar';

export function EXPORT_APPLICATIONS({state}) {

  const accessToken = Cookies.get('accessToken');

  const url = process.env.API_BASE_URL
    + '/events/exportApplications/'
    + state.item.id
    + '?access_token='
    + accessToken;

  window.open(url, '_blank');
}

export function CHANGE_ORDER_BY({state, commit}) {
  commit('SET_ORDER_BY', state.orderBy === 'createdAt' ? 'publishedAt' : 'createdAt');
  return state.orderBy;
}

export function CHANGE_ORDER_DESC({state, commit}) {
  commit('SET_ORDER_DESC', !state.orderDesc);
  return state.orderDesc;
}

export function CHANGE_QUERY({state, commit}) {
  commit('SET_QUERY', state.searchQuery);
  return state.searchQuery;
}

export function CHANGE_WHERE({state, commit}, value) {
  commit('SET_WHERE', value);
  return state.where;
}
