
export function group(state) {

  return state.item
    && state.item.group
    || [];
}

export function isGuide(state) {

  return !!(state.item
    && state.item.id
    && state.item.role
    && state.item.role.name === 'guide');
}
