
export default ({router, store}) => {

  // Clean state.

  router.beforeEach((to, from, next) => {

    // const toName = (to && to.name) || '';
    const fromName = (from && from.name) || '';
    // const toControl = toName.indexOf('control-') === 0;
    // const fromControl = fromName.indexOf('control-') === 0;

    if (fromName) {

      // store.commit('global/RESET');
      store.commit('vlNews/RESET');
      // store.commit('session/RESET');
      store.commit('vlPages/RESET');
      store.commit('vlRoles/RESET');
      store.commit('vlUsers/RESET');
      store.commit('vlEvents/RESET');
      store.commit('vlVendors/RESET');
      store.commit('vlStudios/RESET');
      store.commit('vlPrograms/RESET');
      store.commit('vlFaqPages/RESET');
      store.commit('vlExpoPages/RESET');
      store.commit('vlProducts/RESET');
      store.commit('vlChannels/RESET');
      store.commit('vlRequests/RESET');
      store.commit('vlNewsPages/RESET');
      store.commit('vlQuizPages/RESET');
      store.commit('vlFeedbacks/RESET');
      store.commit('vlAcademies/RESET');
      store.commit('vlGameRoles/RESET');
      store.commit('vlCompanies/RESET');
      store.commit('vlMainPages/RESET');
      store.commit('vlMaterials/RESET');
      store.commit('vlSchedules/RESET');
      store.commit('vlEventTypes/RESET');
      store.commit('vlExtraUsers/RESET');
      store.commit('vlActivities/RESET');
      store.commit('vlSearchPages/RESET');
      store.commit('vlEventsPages/RESET');
      store.commit('vlNavigations/RESET');
      store.commit('vlQuizAnswers/RESET');
      store.commit('vlApplications/RESET');
      store.commit('vlQuizSubjects/RESET');
      store.commit('vlQuizQuestions/RESET');
      store.commit('vlSubscriptions/RESET');
      store.commit('vlEventCategories/RESET');

      // Partial.

      store.commit('global/RESET_SEARCH_MODULE');
    }

    next();
  });
};
