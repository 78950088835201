import axios from 'axios';
import {Cookies} from 'quasar';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import inject from '@src/services/boot-injector';

export const createApi = function ({ssrContext}) {

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies;

  const instance = axios.create({
    baseURL: process.env.API_BASE_URL,
  });

  instance.interceptors.request.use(config => {

    const accessToken = cookies.get(
      'accessToken',
    );

    if (accessToken) {

      config.headers = config.headers || {};
      config.headers.Authorization = accessToken;
    }

    if (
      config.data &&
      config.method === 'post' &&
      !(config.data instanceof FormData)
    ) config.data = omitBy(config.data, isNull);

    return config;
  });

  return {
    api: instance,
  };
};

export default inject(createApi);
