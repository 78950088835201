/**
 * Trim.
 *
 * @param value
 * @returns {string|*}
 */
export function trim(value) {

  if (typeof value !== 'string')
    return value;

  return value.trim();
}
