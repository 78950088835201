import {Cookies} from 'quasar';

export default ({router, store, ssrContext}) => {

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies;

  // Resolve user.

  router.beforeEach(async (to, from, next) => {

    // Is logged in.

    if (store.getters['session/isLoggedIn'])
      return next();

    // No access token.

    const accessToken = cookies.get(
      'accessToken',
    );

    if (!accessToken)
      return next();

    // Resolve user.

    const user = await store.dispatch(
      'session/RESOLVE_USER',
    );

    // Invalid access token.

    if (!user) {

      cookies.remove(
        'accessToken',
        {path: '/'},
      );
    }

    // Continue.

    return next();
  });

  // Checking roles.

  router.beforeEach((to, from, next) => {

    // Fetching required roles.

    let requiredRoles = [];

    to.matched.forEach(record => {

      if (
        record.meta
        && record.meta.requiredRoles
        && Array.isArray(record.meta.requiredRoles)
      ) requiredRoles = requiredRoles.concat(
        record.meta.requiredRoles,
      );
    });

    // No required roles.

    if (!requiredRoles.length)
      return next();

    // Required roles has reset.

    if (requiredRoles.indexOf('$reset') !== -1)
      return next();

    // Is not logged in.

    if (!store.getters['session/isLoggedIn'])
      return next({
        name: 'dialog-login',
        replace: true,
      });

    // Get user role.

    const userRole = store.state
      .session.user.role || {};

    // Checking access.

    let forbidden = false;

    requiredRoles.forEach(role => {

      if (
        role !== userRole.name &&
        role !== '$authenticated'
      ) forbidden = true;
    });

    // Home page or continue.

    forbidden
      ? next({name: 'dialog-login', replace: true})
      : next();
  });
};
