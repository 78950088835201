export function SET_ORDER_BY(state, value) {

  state.orderBy = value || 'createdAt';
}

export function SET_ORDER_DESC(state, value) {

  state.orderDesc = value;
}

export function SET_QUERY(state, value) {

  state.searchQuery = value || '';
}

export function SET_WHERE(state, value) {

  state.where = value;
}
