
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    // limit: 20,
    // total: 0,
    orderBy: 'createdAt',
    orderDesc: true,
    searchBy: [
      'title.ru',
      'title.en',
      'annotation.ru',
      'annotation.en',
      'body.ru',
      'body.en',
    ],
    searchQuery: '',
    where: {},
    // loading: false,
    include: [{
      'studios': 'channels',
    },
    ],
    // fields: [],
  };
}
